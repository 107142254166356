import { Component, DestroyRef, inject, OnInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DirectorService } from "./director.service";
import { Observable, of, forkJoin, Subject, takeUntil } from "rxjs";
import { switchMap, map, catchError } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

@Component({
  selector: 'app-director',
  standalone: true,
  imports: [],
  templateUrl: './director.component.html',
  styleUrls: ['./director.component.css']
})
export class DirectorComponent implements OnInit, AfterViewChecked {
  private destroyRef = inject(DestroyRef);
  destroyed_interval = new Subject();
  formStatus: any = {};
  isReactNativeWebView: boolean = false;
  verifiedFormLinks: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private directorService: DirectorService,
    private http: HttpClient,
  ) { }

  private isUUID(ciphertext: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(ciphertext);
  }
  ngAfterViewChecked(): void {
    this.checkIfReactNativeWebView();

  }
  ngOnInit(): void {
    localStorage.clear();

    this.checkIfReactNativeWebView();

    this.route.params.subscribe(params => {
      const ciphertext = params['ciphertext'];
      if (ciphertext) {
        if (this.isUUID(ciphertext)) {
          localStorage.setItem('verifyCiphertext', ciphertext);
          this.callApiWithCiphertext(ciphertext);
        } else {
          localStorage.setItem('verifyCiphertext', ciphertext);
          localStorage.setItem('formCiphertext', ciphertext);
          this.fetchDataAndStatus(ciphertext);
        }
      } else {
        console.warn('Ciphertext is not provided in the route parameters.');
      }
    });

    this.destroyRef.onDestroy(() => {
      this.destroyed_interval.next('');
      this.destroyed_interval.complete();
    });
  }

  private callApiWithCiphertext(ciphertext: string): void {
    const apiUrl = `${environment.API_URL}/form/director?ciphertext=${ciphertext}`;
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        const message = response?.message;
        const isVerified = message?.['is_verified'];
        this.verifiedFormLinks = message?.verified_form_links || [];
        if (this.verifiedFormLinks.length > 0) {
          localStorage.setItem('verifiedFormLinks', JSON.stringify(this.verifiedFormLinks));
          this.checkAllFormStatuses(isVerified);
        } else {
          localStorage.setItem('formCiphertext', ciphertext);
          this.fetchDataAndStatus(ciphertext);
        }
      },
      error => {
        console.error('API error:', error);
      }
    );
  }

  private checkAllFormStatuses(isVerified: boolean): void {
    const observables = this.verifiedFormLinks.map(link => {
      const formCiphertext = this.extractCiphertextFromLink(link);
      return this.fetchFormData(formCiphertext, isVerified).pipe(
        catchError(error => {
          console.error(`Error fetching form data for ${formCiphertext}:`, error);
          return of({
            error: error.error.message
          } as const);
        })
      );
    });

    forkJoin(observables).subscribe(
      results => {
        let formsArray = results
          .map((result, index) => {
            if (!result) return null;

            if ('patient_id' in result) {
              return {
                index,
                form_id: Number(result?.form_id) || 0,
                status: result?.status,
                patient_id: result.patient_id,
                hospital_id: result.hospital_id,
                hospital_slug: result.hospital_slug,
                form_name: result.form_name,
                meeting_id: result.meeting_id,
                formCiphertext: this.extractCiphertextFromLink(this.verifiedFormLinks[index]),
                errorMessage: null,
                statusCode: null,
              };
            } else {
              return {
                index,
                form_id: 0,
                status: 'error',
                patient_id: null,
                hospital_id: null,
                hospital_slug: result?.error?.hospital_slug, // Store hospital_slug in case of error
                form_name: null,
                meeting_id: null,
                formCiphertext: this.extractCiphertextFromLink(this.verifiedFormLinks[index]),
                errorMessage: result?.error?.form_load_error,
                statusCode: result?.error?.status_code,
              };
            }
          })
          .filter((form): form is NonNullable<typeof form> => form !== null);

        // Get first encountered error details
        const errorMessage = formsArray[0]?.errorMessage || 'Unknown Error';
        const hospitalSlug = formsArray[0]?.hospital_slug || null;

        // Keep only successfully fetched forms
        let incompleteFormsArray = formsArray
          .filter(form => form.status !== 'complete' && form.status !== 'error')
          .sort((a, b) => (a?.form_id || 0) - (b?.form_id || 0))
          .map((form, newIndex) => ({
            ...form,
            index: newIndex,
          }));

        const notCompletedForms = incompleteFormsArray.length > 0 ? incompleteFormsArray : [];

        // Check if all requests failed
        const allRequestsFailed = formsArray.length > 0 && formsArray.every(form => form.status === 'error');

        // Check if all errors are "Form is already submitted"
        const allFormsSubmitted = formsArray.length > 0 && formsArray.every(
          form => form.status === 'error' && form.errorMessage === 'Form is already submitted'
        );

        if (allFormsSubmitted) {
          if (hospitalSlug) {
            localStorage.setItem('hospital_slug', hospitalSlug);
          }
          this.router.navigate(['/error'], { queryParams: { message: 'This form is unavailable because it has already been submitted. If you believe this is a mistake, please contact support using the button below.', title: 'Form Already Submitted' } });
          return;
        }

        if (allRequestsFailed) {
          const validHospitalSlug = hospitalSlug || formsArray.find(form => form.hospital_slug)?.hospital_slug;
          const validErrorMessage = errorMessage || formsArray.find(form => form.errorMessage)?.errorMessage;

          if (validHospitalSlug) {
            localStorage.setItem('hospital_slug', validHospitalSlug);
          }

          if (validErrorMessage === 'Appointment is cancelled') {
            this.router.navigate(['/error'], {
              queryParams: {
                message: 'This form is unavailable because the appointment has been canceled. If you believe this is a mistake, please contact support using the button below.',
                title: 'Cancelled Appointment'
              }
            });
          } else if (validErrorMessage === 'Appointment is already checked-in') {
            this.router.navigate(['/error'], {
              queryParams: {
                message: 'This form is unavailable because the appointment has already been checked in. If you believe this is a mistake, please contact support using the button below.',
                title: 'Checked-in Appointment'
              }
            });
          } else {
            this.router.navigate(['/error'], {
              queryParams: {
                message: 'An unexpected error occurred. Please try again.',
                title: "Can't Load Your Form!"
              }
            });
          }
        }


        // Save only successful forms in local storage
        localStorage.setItem('formsData', JSON.stringify(incompleteFormsArray));

        const allComplete = incompleteFormsArray.length === 0;

        if (allComplete) {
          this.updateLocalStorageForNavigation(formsArray[0]);
          this.router.navigate(['/done']);
        } else if (isVerified && notCompletedForms.length > 0) {
          this.updateLocalStorageForNavigation(notCompletedForms[0]);
          this.router.navigate(['form', notCompletedForms[0]?.form_id]);
        } else if (!isVerified && notCompletedForms.length > 0) {
          this.updateLocalStorageForNavigation(notCompletedForms[0]);
          this.router.navigate(['verification', notCompletedForms[0]?.form_id]);
        } else {
          this.router.navigate(['/error']);
        }
      },
      error => {
        console.error('Unexpected error:', error);
        this.router.navigate(['/error']);
      }
    );
  }





  private updateLocalStorageForNavigation(form: any): void {
    if (!form) return;
    localStorage.setItem('formCiphertext', form.formCiphertext || '');
    localStorage.setItem('form_id', form.form_id?.toString() || '');
    localStorage.setItem('hospital_id', form.hospital_id || '');
    localStorage.setItem('patient_id', form.patient_id || '');
    localStorage.setItem('hospital_slug', form.hospital_slug || '');
    localStorage.setItem('form_name', form.form_name || '');
    localStorage.setItem('meeting_id', form.meeting_id || '');
  }



  private fetchFormData(
    formCiphertext: string,
    isVerified: boolean
  ): Observable<{
    form_id: string;
    status: string;
    patient_id: string;
    hospital_id: string;
    hospital_slug: string;
    form_name: string;
    meeting_id: string;
    is_verified: boolean;
  } | null> {
    const endpoint = `${environment.API_URL}/form/hmsa/cipher/${formCiphertext}`;
    const endpointStatus = `${environment.API_URL}/form/hmsa/${formCiphertext}`;

    return forkJoin({
      formIdResponse: this.http.get<any>(endpoint),
      statusResponse: this.http.get<any>(endpointStatus),
    }).pipe(
      switchMap(({ formIdResponse, statusResponse }) => {
        if (formIdResponse?.['message'] && statusResponse?.['message']) {
          const message = formIdResponse['message'];
          const formStatus = statusResponse['message']['status'];
          const showNdi = statusResponse?.['message']?.['form_data']?.['show_ndi'] ?? true;
          const showOdi = statusResponse?.['message']?.['form_data']?.['show_odi'] ?? true;

          // Save values in localStorage
          localStorage.setItem('showNdi', JSON.stringify(showNdi));
          localStorage.setItem('showOdi', JSON.stringify(showOdi));


          const directorEndpoint = `form/director?ciphertext=${message}`;
          return this.directorService.get(directorEndpoint).pipe(
            map(directorData => {
              if (directorData?.['message']) {
                const {
                  patient_id,
                  hospital_id,
                  hospital_slug,
                  form_id,
                  form_name,
                  meeting_id,
                  is_verified,
                } = directorData['message'];
                const resolved_hospital_slug = hospital_slug === 'athena' ? 'akps' : hospital_slug;

                return {
                  form_id: form_id || '',
                  status: formStatus || 'unknown',
                  patient_id: patient_id || '',
                  hospital_id: hospital_id || '',
                  hospital_slug: resolved_hospital_slug || '',
                  form_name: form_name || '',
                  meeting_id: meeting_id || '',
                  is_verified: is_verified || false,
                };
              }
              console.error(`Invalid director data for message: ${message}`);
              return null; // Explicitly handle the `null` case
            })
          );
        } else {
          console.error(`Invalid form data for ciphertext: ${formCiphertext}`);
          return of(null); // Return `null` wrapped in an Observable
        }
      }),
      takeUntil(this.destroyed_interval)
    );
  }


  private extractCiphertextFromLink(link: string): string {
    const match = link.match(/director\/([^\/]+)/);
    return match ? match[1] : '';
  }

  private redirectToLink(link: string): void {
    window.location.href = link;
  }

  fetchDataAndStatus(ciphertext: string): void {
    const endpoint = `${environment.API_URL}/form/hmsa/cipher/${ciphertext}`;
    const endpointStatus = `${environment.API_URL}/form/hmsa/${ciphertext}`;

    forkJoin({
      dataResponse: this.http.get<any>(endpoint),
      statusResponse: this.http.get<any>(endpointStatus)
    }).pipe(takeUntil(this.destroyed_interval))
      .subscribe(({ dataResponse, statusResponse }) => {
        if (dataResponse?.['message']) {
          const message = dataResponse['message'];
          const directorEndpoint = `form/director?ciphertext=${message}`;
          this.directorService.get(directorEndpoint).pipe(
            takeUntil(this.destroyed_interval)
          ).subscribe(data => {
            this.handleDirectorData(data, statusResponse, ciphertext);
          });
        }
      }, error => {
        console.error('Error fetching user data or status:', error);
        const errorMessage = error?.error?.message?.form_load_error || 'Unknown error occurred';

        if (error?.status === 402) {
          localStorage.setItem('hospital_slug', error.error.message.hospital_slug || '');
          if (errorMessage === 'Form is already submitted') {
            this.router.navigate(['/error'], { queryParams: { message: 'This form is unavailable because it has already been submitted. If you believe this is a mistake, please contact support using the button below.', title: 'Form Already Submitted' } });
          } else if (errorMessage === 'Appointment is cancelled') {
            this.router.navigate(['/error'], { queryParams: { message: 'This form is unavailable because the appointment has been canceled. If you believe this is a mistake, please contact support using the button below.', title: 'Cancelled Appointment' } });
          } else if (errorMessage === 'Appointment is already checked-in') {
            this.router.navigate(['/error'], { queryParams: { message: 'This form is unavailable because the appointment has already been checked in. If you believe this is a mistake, please contact support using the button below.', title: 'Checked-in Appointment' } });
          }
        } else {
          this.router.navigate(['/error'], { queryParams: { message: 'An unexpected error occurred. Please try again.', title: "Can't Load Your Form!" } });
        }
      });
  }

  handleDirectorData(data: any, statusResponse: any, ciphertext: string): void {
    if (data?.['message']?.['patient_id']) {
      const { patient_id, hospital_id, hospital_slug, form_id, form_name, meeting_id, is_verified } = data['message'];
      const resolved_hospital_slug = hospital_slug === 'athena' ? 'akps' : hospital_slug;
      this.storeInLocalStorage({
        patient_id,
        hospital_id,
        form_id,
        form_name,
        hospital_slug: resolved_hospital_slug,
        meeting_id,
        is_verified,
      });
      const showNdi = statusResponse?.['message']?.['form_data']?.['show_ndi'] ?? true;
      const showOdi = statusResponse?.['message']?.['form_data']?.['show_odi'] ?? true;

      // Save values in localStorage
      localStorage.setItem('showNdi', JSON.stringify(showNdi));
      localStorage.setItem('showOdi', JSON.stringify(showOdi));



      const formStatus = statusResponse?.['message']?.['status'] || null;
      this.formStatus = formStatus;

      const currentForm = {
        form_id,
        formCiphertext: ciphertext,
        hospital_id,
        patient_id,
        hospital_slug: resolved_hospital_slug,
        form_name,
        meeting_id,
        status: formStatus || 'unknown',
      };
      this.updateLocalStorageForNavigation(currentForm);

      if (this.formStatus === "complete") {
        this.router.navigate(['/done']);
      } else if (is_verified) {
        this.router.navigate(['form', form_id]);
      } else {
        this.router.navigate(['verification', form_id]);
      }
    } else {
      console.log("Error in data structure");
    }
  }


  storeInLocalStorage(data: any): void {
    Object.keys(data).forEach(key => {
      localStorage.setItem(key, data[key]);
    });
  }

  checkIfReactNativeWebView(): void {
    const metaTag = document.querySelector('meta[name="is_react_native_webview"]');
    const formsMetaTag = document.querySelector('meta[name="forms_count"]');

    this.isReactNativeWebView = !!metaTag;
    localStorage.setItem('isReactNativeWebView', this.isReactNativeWebView.toString());
    if (formsMetaTag) {
      const formCount = formsMetaTag.getAttribute('content');
      if (formCount) {
        localStorage.setItem('formCount', formCount);
      }
    }
  }

  sendMessageToReactNative(flag: string): void {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ flag }));
    } else {
      console.log('ReactNativeWebView not available');
    }
  }
}
