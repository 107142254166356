import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private translationsCache = new Map<string, any>();
    private translationsSubject = new BehaviorSubject<Record<string, any>>({});

    constructor(private translateService: TranslateService) { }

    loadTranslations(formId: string, hospitalSlug: string): Observable<Record<string, any>> {
        const cacheKey = `${this.translateService.currentLang}-${formId}-${hospitalSlug}`;

        if (this.translationsCache.has(cacheKey)) {
            this.translationsSubject.next(this.translationsCache.get(cacheKey));
        } else {
            const langForm = `${this.translateService.currentLang}-${formId}`;
            this.translateService.getTranslation(langForm).subscribe(translations => {
                const mergedTranslations = {
                    ...translations['healent'],
                    ...translations[hospitalSlug]
                };

                this.translationsCache.set(cacheKey, mergedTranslations);
                this.translationsSubject.next(mergedTranslations);
            });
        }

        return this.translationsSubject.asObservable();
    }
}
