<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <ng-container *ngFor="let item of checkboxItems | keyvalue: sortByTitle">
      <div
        class="underline"
        *ngIf="checkboxItems[item.key]?.is_include"
        [ngClass]="{
          error: !myForm.get(item.key)?.value,
          checkboxDone: !!myForm.get(item.key)?.value
        }"
      >
        <app-custom-checkbox
          [formGroup]="myForm"
          [controlName]="item.key"
          label="{{ item.value.title }}"
          (click)="openPrivacyCheck($event, item.key)"
        >
        </app-custom-checkbox>
        <div *ngIf="myForm.get(item.key)?.value" class="signed-by">
          Signed and Approved <span *ngIf="signedBy">By</span> {{ signedBy }} on
          {{ today | date: 'MM/dd/yyyy' }}
        </div>
        <div *ngIf="checkboxItems[item.key]?.showDecline" class="not-signed-by">
          Decline <span *ngIf="signedBy">By</span> {{ signedBy }} on
          {{ today | date: 'MM/dd/yyyy' }}
        </div>
      </div>
    </ng-container>
    <p class="m-t-l allGood-text" *ngIf="!hasDisplayableItems">
      All good! All consents are signed.
    </p>
  </ng-container>
</form>
