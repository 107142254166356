import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  titles: any = {
    'akps': 'AK Pain & Spine',
    'cps': 'Commonwealth Pain & Spine',
    'heal': 'Healent',
  }
  constructor(
  ) {
  }
  setFavIcon(slug: string) {
    const icon = document.querySelector('link[rel="icon"]');
    if (icon) {
      icon.setAttribute('href', `${slug}-fav.ico`);
    } else {
      console.error('Meta tag with property "twitter:image" not found');
    }
    document.title = this.titles[slug];
  }
}
