<form [formGroup]="myForm" style="margin-top: -16px">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveBackPain"
      label="{{ radioItems['doYouHaveBackPain']['title'] }}"
      [options]="radioItems['doYouHaveBackPain']['options']"
      *ngIf="radioItems['doYouHaveBackPain']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <div
      class="m-t-m d-block"
      *ngIf="myForm.get('doYouHaveBackPain')?.value === 'yes'"
    >
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['painIntensity']['is_include']"
        controlName="painIntensity"
        [label]="radioItems['painIntensity']?.title"
        [options]="radioItems['painIntensity']?.options"
        [iconClass]="radioItems['painIntensity']?.icon"
        [hint]="radioItems['painIntensity']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['personalCareWashingDressingEtc']['is_include']"
        controlName="personalCareWashingDressingEtc"
        [label]="radioItems['personalCareWashingDressingEtc']?.title"
        [options]="radioItems['personalCareWashingDressingEtc']?.options"
        [iconClass]="radioItems['personalCareWashingDressingEtc']?.icon"
        [hint]="radioItems['personalCareWashingDressingEtc']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['lifting']['is_include']"
        controlName="lifting"
        [label]="radioItems['lifting']?.title"
        [options]="radioItems['lifting']?.options"
        [iconClass]="radioItems['lifting']?.icon"
        [hint]="radioItems['lifting']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['walking']['is_include']"
        controlName="walking"
        [label]="radioItems['walking']?.title"
        [options]="radioItems['walking']?.options"
        [iconClass]="radioItems['walking']?.icon"
        [hint]="radioItems['walking']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['sitting']['is_include']"
        controlName="sitting"
        [label]="radioItems['sitting']?.title"
        [options]="radioItems['sitting']?.options"
        [iconClass]="radioItems['sitting']?.icon"
        [hint]="radioItems['sitting']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['standing']['is_include']"
        controlName="standing"
        [label]="radioItems['standing']?.title"
        [options]="radioItems['standing']?.options"
        [iconClass]="radioItems['standing']?.icon"
        [hint]="radioItems['standing']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['sleeping']['is_include']"
        controlName="sleeping"
        [label]="radioItems['sleeping']?.title"
        [options]="radioItems['sleeping']?.options"
        [iconClass]="radioItems['sleeping']?.icon"
        [hint]="radioItems['sleeping']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['sexLife']['is_include']"
        controlName="sexLife"
        [label]="radioItems['sexLife']?.title"
        [options]="radioItems['sexLife']?.options"
        [iconClass]="radioItems['sexLife']?.icon"
        [hint]="radioItems['sexLife']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['socialLife']['is_include']"
        controlName="socialLife"
        [label]="radioItems['socialLife']?.title"
        [options]="radioItems['socialLife']?.options"
        [iconClass]="radioItems['socialLife']?.icon"
        [hint]="radioItems['socialLife']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['traveling']['is_include']"
        controlName="traveling"
        [label]="radioItems['traveling']?.title"
        [options]="radioItems['traveling']?.options"
        [iconClass]="radioItems['traveling']?.icon"
        [hint]="radioItems['traveling']?.hint"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
    </div>
  </ng-container>
</form>
