<form [formGroup]="myForm">
  <h2 class="h2-section-title">
    Select any item from the following lists that applies to you
  </h2>

  <app-checkbox-group-as-array
    [formGroup]="myForm"
    controlName="familyHistory"
    *ngIf="checkboxesItems['familyHistory']?.is_include"
    label="{{ checkboxesItems['familyHistory']['title'] }}"
    [options]="checkboxesItems['familyHistory']['options']"
    [required]="checkboxesItems['familyHistory']?.required"
    [patchValues]="patch_values['familyHistory']"
  >
  </app-checkbox-group-as-array>

  <app-checkbox-group-as-array
    [formGroup]="myForm"
    controlName="personalHistory"
    *ngIf="checkboxesItems['personalHistory']?.is_include"
    label="{{ checkboxesItems['personalHistory']['title'] }}"
    [options]="checkboxesItems['personalHistory']['options']"
    [required]="checkboxesItems['personalHistory']?.required"
    [patchValues]="patch_values['personalHistory']"
  >
  </app-checkbox-group-as-array>
  <app-checkbox-group-as-array
    [formGroup]="myForm"
    controlName="ageCategory"
    *ngIf="checkboxesItems['ageCategory']?.is_include"
    label="{{ checkboxesItems['ageCategory']['title'] }}"
    [options]="checkboxesItems['ageCategory']['options']"
    [required]="checkboxesItems['ageCategory']?.required"
    [patchValues]="patch_values['ageCategory']"
  >
  </app-checkbox-group-as-array>
  <app-custom-radio-group-array
    [formGroup]="myForm"
    controlName="gender"
    label="{{ radioItems['gender']['title'] }}"
    [options]="radioItems['gender']['options']"
    *ngIf="radioItems['gender']?.is_include"
    [selectFirstOption]="true"
    [id]="'gender'"
    class="flex-row-reverse"
  >
  </app-custom-radio-group-array>
  <app-checkbox-group-as-array
    [formGroup]="myForm"
    controlName="sexualAbuse"
    *ngIf="checkboxesItems['sexualAbuse']?.is_include"
    label="{{ checkboxesItems['sexualAbuse']['title'] }}"
    [options]="checkboxesItems['sexualAbuse']['options']"
    [required]="checkboxesItems['sexualAbuse']?.required"
    [patchValues]="patch_values['sexualAbuse']"
    class="m-t-l d-block"
  >
  </app-checkbox-group-as-array>

  <app-checkbox-group-as-array
    [formGroup]="myForm"
    controlName="psychologicalDisease"
    *ngIf="checkboxesItems['psychologicalDisease']?.is_include"
    label="{{ checkboxesItems['psychologicalDisease']['title'] }}"
    [options]="checkboxesItems['psychologicalDisease']['options']"
    [required]="checkboxesItems['psychologicalDisease']?.required"
    [patchValues]="patch_values['psychologicalDisease']"
  >
  </app-checkbox-group-as-array>
</form>
