import { Component } from '@angular/core';

@Component({
  selector: 'app-cwps-controlled-substance-agreement',
  standalone: true,
  imports: [],
  templateUrl: './cwps-controlled-substance-agreement.component.html',
  styleUrl: './cwps-controlled-substance-agreement.component.css'
})
export class CwpsControlledSubstanceAgreement {

}
