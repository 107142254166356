<app-header
  [ngClass]="{ 'fixed-header': currentStep !== 'stepper' }"
></app-header>
<ng-container *ngIf="is_loading || !showForm">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-container>
<div
  class="body-container"
  [ngClass]="{ 'single-step': isSingleStep(), hide: showForm === false }"
>
  <ng-container *ngIf="!is_loading && form_loaded">
    <ng-container *ngIf="currentStep === 'stepper'">
      <mat-stepper
        class="stepper"
        [animationDuration]="'0'"
        (animationDone)="scrollToTopOfSelectedStep()"
        orientation="vertical"
        (selectionChange)="onStepChange($event)"
        [linear]="isLinear"
        #stepper
      >
        <mat-step
          *ngFor="let step of getFilteredStepItems(); let i = index"
          [stepControl]="
            isDynamicComponentRefValid(step.key)
              ? dynamicComponentRef[step.key].instance.formGroup
              : null
          "
          [completed]="isStepValid(step.key, i)"
          [editable]="isStepValid(step.key, i)"
        >
          <ng-template matStepperIcon="edit">
            <!-- Custom icon for current step -->
            <mat-icon>done</mat-icon>
          </ng-template>

          <ng-template *ngIf="stepItems.length === 1" matStepLabel>
            <span #stepTitle class="stepTitle"></span>
            <div
              [ngClass]="{
                error:
                  isDynamicComponentRefValid(step.key) &&
                  !dynamicComponentRef[step.key].instance.formGroup.valid &&
                  dynamicComponentRef[step.key].instance.formGroup.touched
              }"
              id="step-title-{{ i }}"
            >
              {{ step.title }}
            </div>
          </ng-template>
          <ng-template *ngIf="stepItems.length > 1" matStepLabel>
            <div
              [ngClass]="{
                error:
                  isDynamicComponentRefValid(step.key) &&
                  !dynamicComponentRef[step.key].instance.formGroup.valid &&
                  dynamicComponentRef[step.key].instance.formGroup.touched
              }"
              #stepTitle
              id="step-title-{{ i }}"
            >
              {{ step.title }}
            </div>
          </ng-template>

          <form [formGroup]="myForm" novalidate>
            <ng-container #containers></ng-container>
            <div class="next-btn btn-right">
              <button
                id="back_button"
                mat-button
                matStepperPrevious
                *ngIf="i > 0"
                class="back-btn"
              >
                Back
              </button>

              <!-- Check if it's the last step -->
              <button
                id="next_button"
                mat-button
                *ngIf="i < getFilteredStepItems().length - 1"
                matStepperNext
                [disabled]="
                  !isDynamicComponentRefValid(step.key) ||
                  !dynamicComponentRef[step.key].instance.formGroup.valid
                "
              >
                Next
              </button>
              <button
                id="submit_button"
                *ngIf="i === getFilteredStepItems().length - 1"
                mat-button
                [disabled]="
                  (['7', '8', '9', '10', '11', '12'].includes(formID ?? '') &&
                    (!isDynamicComponentRefValid(step.key) ||
                      !dynamicComponentRef[step.key]?.instance?.formGroup
                        ?.valid)) ||
                  (['8'].includes(formID ?? '') && isSubmitDisabled) ||
                  isSubmitting
                "
                type="submit"
                (click)="onSubmit()"
              >
                <span *ngIf="formCount === 1">Submit</span>
                <span *ngIf="formCount > 1">Next form</span>
              </button>
            </div>
          </form>
          <div class="submitting w-100" style="position: relative">
            <div
              *ngIf="isSubmitting"
              [ngClass]="{ 'submission-overlay-anim': isSubmitting }"
              class="submission-overlay"
            >
              <div class="overlay-message">
                <div class="overlay-item">
                  <app-lottie-player
                    [animationPath]="animationPath"
                  ></app-lottie-player>
                  <div class="overlay-text">
                    <h1
                      style="margin: 0"
                      [ngClass]="{ 'sentence-1': isSubmitting }"
                    >
                      Preparing the info...
                    </h1>
                    <h1
                      style="margin: 0"
                      [ngClass]="{ 'sentence-2': isSubmitting }"
                    >
                      Submitting the form...
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </ng-container>
    <ng-container *ngIf="currentStep === 'error'">
      <app-error></app-error>
    </ng-container>
  </ng-container>
</div>
