export const environment = {
  production: false,
  // API_URL: "http://127.0.0.1:8050",
  API_URL: "https://apipracticegwdev.healent.com",
  API_DOMAIN_LEGACY: "dev.healent.com",
  API_AUTH_URL: "https://apipracticegwdev.healent.com",
  ECS_URL: "https://api-dev.healent.com",
  NAVIGATOR_API_URL: "https://api-dev.healent.com",
  PAYMENT_LINK: 'https://secure.heal.dev.healent.com',
  environment: "development",
  domain: "healent.com"
};
