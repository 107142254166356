import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatIconModule } from "@angular/material/icon";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component';
import { LanguageService } from '../../../services/language.service';
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from '../../../services/translation.service';
@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    LanguageSelectorComponent,
    TranslateModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Output() next = new EventEmitter<void>();
  formStatus: any = {};
  userData: any = {};
  formsInfo: any = {};
  userName: any = {};
  hospital_slug: string = '';
  form_id: string = '';
  hospital_id = 0;
  patient_id = 0;
  isHidden: boolean = true;

  private destroySubject = new Subject<void>();

  constructor(
    private bottomSheet: MatBottomSheet,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
    this.loadFromLocalStorage();
  }

  loadFromLocalStorage(): void {
    // Load values from localStorage instead of route parameters
    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.hospital_id = Number(localStorage.getItem('hospital_id')) || 0;
    this.patient_id = Number(localStorage.getItem('patient_id')) || 0;
    this.form_id = localStorage.getItem('form_id') || '';
  }

  ngOnInit(): void {
    this.loadTranslations();
  }

  loadTranslations(): void {
    this.translationService.loadTranslations(this.form_id, this.hospital_slug)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(translations => {
        this.formsInfo = translations['formsInfo'];
        this.fetchUserData();
        this.cdr.detectChanges();
      });
  }

  fetchUserData(): void {
    const formCiphertext = localStorage.getItem('formCiphertext');
    const endpointStatus = `${environment.API_URL}/form/hmsa/${formCiphertext}`;
    const endpointUserData = `${environment.API_URL}/form/hmsa/user/${formCiphertext}`;

    this.http.get<any>(endpointStatus).subscribe(
      response => {
        if (response && response['message']?.['status']) {
          this.formStatus = response['message']['status'];
        }
      },
      error => {
        console.error('Error fetching form status:', error);
      }
    );

    this.http.get<any>(endpointUserData).subscribe(
      response => {
        if (response) {
          this.userName = response.message[0];
          this.userName = response.message[0].name;
        }
      },
      error => {
        console.error('Error fetching user data:', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formStatus'] && changes['formStatus'].currentValue) {
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  goToNextStep() {
    this.next.emit();
  }

  get getLanguage() {
    return this.languageService.getCurrentLanguage()['title'];
  }

  selectLanguage() {
    this.bottomSheet.open(LanguageSelectorComponent);
  }

  getDisplayName(): string {
    return this.userName || '';
  }


}
