import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-radio-group-array',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule
  ],
  templateUrl: './custom-radio-group-array.component.html',
  styleUrls: ['./custom-radio-group-array.component.css']
})
export class CustomRadioGroupArrayComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() options!: { [key: string]: string };
  @Input() selectFirstOption: boolean = false;
  @Input() id?: String;

  ngOnInit() {
    const control = this.formGroup.get(this.controlName) as FormControl;

    const patchedValue = this.formGroup.get(this.controlName)?.value;

    if (patchedValue !== null && patchedValue !== undefined) {
      control.setValue(patchedValue, { emitEvent: false });
    }
    else if (this.selectFirstOption && this.options && Object.keys(this.options).length > 0) {
      const firstOptionValue = Object.keys(this.options)[0];
      control.setValue(firstOptionValue, { emitEvent: false });
    }

    control.markAsTouched();
  }



  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (!control) {
      throw new Error(`Control '${this.controlName}' not found in FormGroup`);
    }
    return control as FormControl;
  }

  get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
