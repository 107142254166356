import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, ReactiveFormsModule, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';

// Custom validator to check if at least one checkbox is checked
export function atLeastOneCheckedValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!(group instanceof FormGroup)) return null;
    const isAtLeastOneChecked = Object.values(group.value).some(value => value === true);
    return isAtLeastOneChecked ? null : { atLeastOneChecked: true };
  };
};

interface CheckboxOptions {
  [key: string]: { label: string; questionId?: string };
}

@Component({
  selector: 'app-checkbox-group-as-array',
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckboxModule, CommonModule],
  templateUrl: './checkbox-group-as-array.component.html',
  styleUrls: ['./checkbox-group-as-array.component.css']
})
export class CheckboxGroupAsArrayComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() options!: CheckboxOptions;
  @Input() label!: string;
  @Input() icon!: string;
  @Input() subLabel!: string;
  @Input() subLabelClass!: string;
  @Input() required: boolean = false;
  @Input() patchValues: any = {};

  @Output() valueChanged = new EventEmitter<{ controlName: string; value: any }>();

  private isUpdating = false; // 🔴 Prevent recursive updates

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    const controls = Object.keys(this.options).reduce((acc, key) => {
      acc[key] = this.fb.control(false);
      return acc;
    }, {} as { [key: string]: FormControl });

    const checkboxGroup = this.fb.group(controls);
    this.formGroup.addControl(this.controlName, checkboxGroup);

    if (this.required) {
      this.group.setValidators(atLeastOneCheckedValidator());
    }

    // ✅ Ensure re-validation on change without recursive calls
    this.group.valueChanges.subscribe(() => {
      if (this.isUpdating) return; // Prevent infinite loop
      this.isUpdating = true;

      this.group.updateValueAndValidity({ emitEvent: false });
      this.formGroup.updateValueAndValidity({ emitEvent: false });

      this.valueChanged.emit({ controlName: this.controlName, value: this.group.value });

      this.isUpdating = false;
    });

    // ✅ Patch values and trigger validation
    if (this.patchValues && this.patchValues.options) {
      setTimeout(() => this.applyPatchValues(), 0);
    }
  }

  private applyPatchValues() {
    const formControlGroup = this.group;
    if (!formControlGroup) return;

    Object.keys(this.options).forEach(optionKey => {
      const questionId = this.options[optionKey]?.questionId;
      const patchedValue = this.patchValues.options.some((opt: any) => opt.questionId === questionId && opt.value === "yes");

      if (formControlGroup.get(optionKey)?.value !== patchedValue) {
        formControlGroup.get(optionKey)?.setValue(patchedValue, { emitEvent: false });
      }
    });

    // ✅ Ensure validation is triggered after patching
    formControlGroup.updateValueAndValidity({ emitEvent: false });
  }

  get group(): FormGroup {
    return this.formGroup.get(this.controlName) as FormGroup;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
