import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

import { BodyBackComponent } from './body-back/body-back.component';
import { BodyFrontComponent } from './body-front/body-front.component';
import { BodyLeftComponent } from './body-left/body-left.component';
import { BodyRightComponent } from './body-right/body-right.component';
import { BottomSelectorComponent } from './bottom-selector/bottom-selector.component';
import { BottomSliderComponent } from './bottom-slider/bottom-slider.component';
import { markLabel, painFeelList, painFeelTitle } from './body-service';

@Component({
  selector: 'app-body-viewer',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatIconModule,
    BodyBackComponent,
    BodyFrontComponent,
    BodyLeftComponent,
    BodyRightComponent,
    NgIf,
  ],
  templateUrl: './body-viewer.component.html',
  styleUrls: ['./body-viewer.component.css'],
})
export class BodyViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() selectedListTitle!: string;
  @Input() worseListTitle!: string;
  @Input() worseListInclude!: boolean;
  @Input() points: any[] = [];
  @Output() clickPoint = new EventEmitter<any>();
  @ViewChild('svgElement', { static: false }) svgElement!: ElementRef<SVGElement>;

  public bodyDirection = 'front';
  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    if (this.formGroup && this.controlName) {
      this.formGroup.addControl(this.controlName, new FormControl(this.points));
      this.points = this.formGroup.get(this.controlName)?.value || [];
    }

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.points = val[this.controlName] || [];
      this.initPoints();
      this.clickPoint.emit(val[this.controlName]);
    });

  }

  ngAfterViewInit() {
    setTimeout(() => this.initPoints(), 100);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('points' in changes) {
      setTimeout(() => this.initPoints(), 100);
    }
  }

  initPoints() {
    const svgElements = document.querySelectorAll('.body-selector svg');
    svgElements.forEach(svgElement => {
      const paths = svgElement.querySelectorAll('path');
      paths.forEach((path: SVGPathElement) => {
        const pathId = path.id;
        const index = (this.points || []).findIndex((point: any) => point.id === pathId);
        if (index > -1) {
          const point = this.points[index];
          const rate = point.rate || '5';
          path.classList.add('svgSelected');
          path.classList.add(`color-${rate}`);
          path.classList.remove('svgUnselected');
          if (point.x && point.y) {
            this.appendMark(path, markLabel[point.value], point.value, pathId, { x: point.x, y: point.y });
          }
        } else {
          path.classList.add('svgUnselected');
          path.classList.remove('svgSelected');
          this.removeMark(pathId);
        }
      });
    });
  }

  selectBodyDirection(direction: string): void {
    this.bodyDirection = direction;
    setTimeout(() => this.initPoints(), 100); // Re-initialize points when the body direction changes
  }

  handleSvgClick(event: MouseEvent): void {
    const clickedElement = event.target as SVGPathElement;
    if (clickedElement.tagName !== 'path') return;

    const clickedElementId = clickedElement.id;
    const isCurrentlySelected = clickedElement.classList.contains('svgSelected');
    if (isCurrentlySelected) {
      this.deselectPoint(clickedElementId);
    } else {
      this.bottomSheet.open(BottomSelectorComponent, {
        data: { items: painFeelList, selectedItem: { value: [] } },
      }).afterDismissed().subscribe((result: string[]) => {
        if (result && result.length) {
          const boundingBox = clickedElement.getBBox();
          const x = boundingBox.x + boundingBox.width / 2;
          const y = boundingBox.y + boundingBox.height / 2;

          // Open BottomSliderComponent after selection
          this.bottomSheet.open(BottomSliderComponent, {
            data: {
              formGroup: this.formGroup,
              formName: this.controlName,
            }
          }).afterDismissed().subscribe((sliderResult) => {
            if (sliderResult) {
              this.updatePoints(clickedElementId, result, sliderResult.rate, x, y);
              this.appendMark(clickedElement, result[0], result, clickedElementId, { x, y });
              this.updateFormControl();
            }
          });
        }
      });
    }

  }

  updatePoints(id: string, values: string[], rate: number, x: number, y: number) {
    const existingPointIndex = this.points.findIndex(point => point.id === id);

    if (existingPointIndex > -1) {
      // Update existing point
      this.points[existingPointIndex].value = values;
      this.points[existingPointIndex].rate = rate;
    } else {
      // Add new point
      this.points.push({ id, value: values, rate, x, y, worstArea: false });
    }
  }

  selectRate(clickedElement: SVGPathElement, value: string, x: number, y: number) {
    this.bottomSheet.open(BottomSliderComponent, {
      data: {
        formGroup: this.formGroup,
        formName: this.controlName,
      }
    }).afterDismissed().subscribe((result) => {
      if (result) {
        const clickedElementId = clickedElement.id;

        // Remove all classes that start with 'color-'
        clickedElement.classList.forEach(className => {
          if (className.startsWith('color-')) {
            clickedElement.classList.remove(className);
          }
        });

        // Add new classes
        clickedElement.classList.add('svgSelected');
        clickedElement.classList.add(`color-${result['rate']}`);
        clickedElement.classList.remove('svgUnselected');

        const existingPointIndex = this.points.findIndex(point => point.id === clickedElementId && point.value === value);
        if (existingPointIndex === -1) {
          this.points.push({ id: clickedElementId, value, rate: result['rate'], x, y, worstArea: false });
          this.updateFormControl();
        }
      }
    });
  }



  updateFormControl() {
    const control = this.formGroup.get(this.controlName);
    if (control) {
      control.setValue(this.points, { emitEvent: true });
    }
  }


  deselectPoint(id: string) {
    const clickedElement = document.querySelector(`#${id}`) as SVGPathElement;

    if (clickedElement) {
      clickedElement.classList.remove('svgSelected');
      clickedElement.classList.add('svgUnselected');

      // Find the index of the point
      const index = this.points.findIndex((point) => point.id === id);

      if (index > -1) {
        this.points.splice(index, 1); // Remove the point at the found index
      }

      this.removeMark(id);
      this.updateFormControl();
    }
  }



  removeMark(id: string) {

    const svgElements = document.querySelectorAll('.body-selector svg');
    svgElements.forEach(svgElement => {
      const markElement = svgElement.querySelector(`.pain-mark-${id}`);
      if (markElement) {
        markElement.parentNode?.removeChild(markElement);
      }
    });
  }

  appendMark(path: SVGPathElement, painType: string, values: string[] | undefined, id: string, position: { x: number, y: number }) {
    const safeValues = values && Array.isArray(values) ? values : [];

    this.removeMark(id);

    const svgParent = path.parentNode;
    if (!svgParent) return;

    const groupElement = document.createElementNS("http://www.w3.org/2000/svg", "g");
    groupElement.setAttribute('class', `pain-mark pain-mark-${id}`);

    const circleElement = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circleElement.setAttribute('cx', position.x.toString());
    circleElement.setAttribute('cy', position.y.toString());
    circleElement.setAttribute('r', '0.25');
    circleElement.setAttribute('fill', 'white');
    circleElement.setAttribute('stroke', 'black');
    circleElement.setAttribute('stroke-width', '0.01');

    const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
    textElement.setAttribute('x', position.x.toString());
    textElement.setAttribute('y', position.y.toString());
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('alignment-baseline', 'middle');
    textElement.setAttribute('dominant-baseline', 'middle');

    // Check if multiple values exist and set "M" or first value
    textElement.innerHTML = safeValues.length > 1 ? 'M' : (painType || '');

    // Add click event to remove the mark
    groupElement.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the SVG
      this.deselectPoint(id);
    });

    groupElement.appendChild(circleElement);
    groupElement.appendChild(textElement);
    svgParent.appendChild(groupElement);
  }




  formatPointIdForSelectList(id: string): string {
    const parts = id.split('_');
    if (parts.length > 1) {
      const formattedParts = parts.map((part, index) => {
        if (index === 0) {
          return part.toUpperCase();
        } else {
          return part.replace(/([A-Z])/g, ' $1').trim().replace(/ +/g, ' ');
        }
      }).map(part => {
        return part.charAt(0).toUpperCase() + part.slice(1);
      });

      return formattedParts.join('.') + ',';
    }
    return id;
  }

  formatPointIdForWorstList(id: string): string {
    const parts = id.split('_');
    if (parts.length > 1) {
      const formattedParts = parts.map((part, index) => {
        if (index === 0) {
          return part.toUpperCase();
        } else {
          return part.replace(/([A-Z])/g, ' $1').trim().replace(/ +/g, ' ');
        }
      }).map(part => {
        return part.charAt(0).toUpperCase() + part.slice(1);
      });

      return formattedParts.join('.');
    }
    return id;
  }

  handleCheckboxChange(event: any, id: string) {
    const point = this.points.find((p) => p.id === id);
    if (point) {
      point.worstArea = event.source.checked;
    }
  }

}
