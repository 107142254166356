<form [formGroup]="myForm" style="margin-top: -16px">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveBackPain"
      label="{{ radioItems['doYouHaveBackPain']['title'] }}"
      [options]="radioItems['doYouHaveBackPain']['options']"
      *ngIf="showODI && radioItems['doYouHaveBackPain']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <ng-container
      *ngIf="showODI && myForm.get('doYouHaveBackPain')?.value === 'yes'"
    >
      <div class="m-t-m d-block">
        <app-select-as-array
          id="pain_intensity"
          [formGroup]="myForm"
          *ngIf="radioItems['painIntensity']['is_include']"
          controlName="painIntensity"
          [label]="radioItems['painIntensity']?.title"
          [options]="radioItems['painIntensity']?.options"
          [iconClass]="radioItems['painIntensity']?.icon"
          [hint]="radioItems['painIntensity']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="personalcare"
          [formGroup]="myForm"
          *ngIf="radioItems['personalCareWashingDressingEtc']['is_include']"
          controlName="personalCareWashingDressingEtc"
          [label]="radioItems['personalCareWashingDressingEtc']?.title"
          [options]="radioItems['personalCareWashingDressingEtc']?.options"
          [iconClass]="radioItems['personalCareWashingDressingEtc']?.icon"
          [hint]="radioItems['personalCareWashingDressingEtc']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="lifting"
          [formGroup]="myForm"
          *ngIf="radioItems['lifting']['is_include']"
          controlName="lifting"
          [label]="radioItems['lifting']?.title"
          [options]="radioItems['lifting']?.options"
          [iconClass]="radioItems['lifting']?.icon"
          [hint]="radioItems['lifting']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="walking"
          [formGroup]="myForm"
          *ngIf="radioItems['walking']['is_include']"
          controlName="walking"
          [label]="radioItems['walking']?.title"
          [options]="radioItems['walking']?.options"
          [iconClass]="radioItems['walking']?.icon"
          [hint]="radioItems['walking']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="sitting"
          [formGroup]="myForm"
          *ngIf="radioItems['sitting']['is_include']"
          controlName="sitting"
          [label]="radioItems['sitting']?.title"
          [options]="radioItems['sitting']?.options"
          [iconClass]="radioItems['sitting']?.icon"
          [hint]="radioItems['sitting']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="standing"
          [formGroup]="myForm"
          *ngIf="radioItems['standing']['is_include']"
          controlName="standing"
          [label]="radioItems['standing']?.title"
          [options]="radioItems['standing']?.options"
          [iconClass]="radioItems['standing']?.icon"
          [hint]="radioItems['standing']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="sleeping"
          [formGroup]="myForm"
          *ngIf="radioItems['sleeping']['is_include']"
          controlName="sleeping"
          [label]="radioItems['sleeping']?.title"
          [options]="radioItems['sleeping']?.options"
          [iconClass]="radioItems['sleeping']?.icon"
          [hint]="radioItems['sleeping']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="sex_life"
          [formGroup]="myForm"
          *ngIf="radioItems['sexLife']['is_include']"
          controlName="sexLife"
          [label]="radioItems['sexLife']?.title"
          [options]="radioItems['sexLife']?.options"
          [iconClass]="radioItems['sexLife']?.icon"
          [hint]="radioItems['sexLife']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="social_life"
          [formGroup]="myForm"
          *ngIf="radioItems['socialLife']['is_include']"
          controlName="socialLife"
          [label]="radioItems['socialLife']?.title"
          [options]="radioItems['socialLife']?.options"
          [iconClass]="radioItems['socialLife']?.icon"
          [hint]="radioItems['socialLife']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="traveling"
          [formGroup]="myForm"
          *ngIf="radioItems['traveling']['is_include']"
          controlName="traveling"
          [label]="radioItems['traveling']?.title"
          [options]="radioItems['traveling']?.options"
          [iconClass]="radioItems['traveling']?.icon"
          [hint]="radioItems['traveling']?.hint"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
      </div>
    </ng-container>

    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveNeckPain"
      label="{{ radioItems['doYouHaveNeckPain']['title'] }}"
      [options]="radioItems['doYouHaveNeckPain']['options']"
      *ngIf="showNDI && radioItems['doYouHaveNeckPain']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <ng-container
      *ngIf="showNDI && myForm.get('doYouHaveNeckPain')?.value === 'yes'"
    >
      <div class="m-t-m d-block">
        <app-select-as-array
          id="neck_pain_intensity"
          [formGroup]="myForm"
          *ngIf="radioItems['neckPainIntensity']['is_include']"
          controlName="neckPainIntensity"
          [label]="radioItems['neckPainIntensity']?.title"
          [options]="radioItems['neckPainIntensity']?.options"
          [iconClass]="radioItems['neckPainIntensity']?.icon"
          [hint]="radioItems['neckPainIntensity']?.hint"
          [questionId]="radioItems['neckPainIntensity']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_personal_care"
          [formGroup]="myForm"
          *ngIf="radioItems['neckPersonalCare']['is_include']"
          controlName="neckPersonalCare"
          [label]="radioItems['neckPersonalCare']?.title"
          [options]="radioItems['neckPersonalCare']?.options"
          [iconClass]="radioItems['neckPersonalCare']?.icon"
          [hint]="radioItems['neckPersonalCare']?.hint"
          [questionId]="radioItems['neckPersonalCare']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_lifting"
          [formGroup]="myForm"
          *ngIf="radioItems['neckLifting']['is_include']"
          controlName="neckLifting"
          [label]="radioItems['neckLifting']?.title"
          [options]="radioItems['neckLifting']?.options"
          [iconClass]="radioItems['neckLifting']?.icon"
          [hint]="radioItems['neckLifting']?.hint"
          [questionId]="radioItems['neckLifting']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_reading"
          [formGroup]="myForm"
          *ngIf="radioItems['neckReading']['is_include']"
          controlName="neckReading"
          [label]="radioItems['neckReading']?.title"
          [options]="radioItems['neckReading']?.options"
          [iconClass]="radioItems['neckReading']?.icon"
          [hint]="radioItems['neckReading']?.hint"
          [questionId]="radioItems['neckReading']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_headaches"
          [formGroup]="myForm"
          *ngIf="radioItems['neckHeadaches']['is_include']"
          controlName="neckHeadaches"
          [label]="radioItems['neckHeadaches']?.title"
          [options]="radioItems['neckHeadaches']?.options"
          [iconClass]="radioItems['neckHeadaches']?.icon"
          [hint]="radioItems['neckHeadaches']?.hint"
          [questionId]="radioItems['neckHeadaches']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_concentration"
          [formGroup]="myForm"
          *ngIf="radioItems['neckConcentration']['is_include']"
          controlName="neckConcentration"
          [label]="radioItems['neckConcentration']?.title"
          [options]="radioItems['neckConcentration']?.options"
          [iconClass]="radioItems['neckConcentration']?.icon"
          [hint]="radioItems['neckConcentration']?.hint"
          [questionId]="radioItems['neckConcentration']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_work"
          [formGroup]="myForm"
          *ngIf="radioItems['neckWork']['is_include']"
          controlName="neckWork"
          [label]="radioItems['neckWork']?.title"
          [options]="radioItems['neckWork']?.options"
          [iconClass]="radioItems['neckWork']?.icon"
          [hint]="radioItems['neckWork']?.hint"
          [questionId]="radioItems['neckWork']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_driving"
          [formGroup]="myForm"
          *ngIf="radioItems['neckDriving']['is_include']"
          controlName="neckDriving"
          [label]="radioItems['neckDriving']?.title"
          [options]="radioItems['neckDriving']?.options"
          [iconClass]="radioItems['neckDriving']?.icon"
          [hint]="radioItems['neckDriving']?.hint"
          [questionId]="radioItems['neckDriving']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_sleeping"
          [formGroup]="myForm"
          *ngIf="radioItems['neckSleeping']['is_include']"
          controlName="neckSleeping"
          [label]="radioItems['neckSleeping']?.title"
          [options]="radioItems['neckSleeping']?.options"
          [iconClass]="radioItems['neckSleeping']?.icon"
          [hint]="radioItems['neckSleeping']?.hint"
          [questionId]="radioItems['neckSleeping']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
        <app-select-as-array
          id="neck_recreation"
          [formGroup]="myForm"
          *ngIf="radioItems['neckRecreation']['is_include']"
          controlName="neckRecreation"
          [label]="radioItems['neckRecreation']?.title"
          [options]="radioItems['neckRecreation']?.options"
          [iconClass]="radioItems['neckRecreation']?.icon"
          [hint]="radioItems['neckRecreation']?.hint"
          [questionId]="radioItems['neckRecreation']?.questionId"
          [hasScore]="true"
          class="m-t-l d-block"
        >
        </app-select-as-array>
      </div>
    </ng-container>
    <p class="m-t-l allGood-text" *ngIf="!showNDI && !showODI">
      All good! pleas go to next step.
    </p>
  </ng-container>
</form>
