import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PatientService {

    constructor(private http: HttpClient) { }

    private getCiphertext(): string {
        const ciphertext = localStorage.getItem('securCiphertext') || '';
        if (!ciphertext) {
            throw new Error('Ciphertext is missing.');
        }
        return ciphertext;
    }

    private handleUnauthorized(error: any): Observable<never> {
        if (error.status === 403) {
            console.error('Access denied: is_verified is false or authorization failed.', error);
        }
        return throwError(() => error);
    }

    getPatientInfo(patientId: string): Observable<any> {
        const ciphertext = this.getCiphertext();

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const apiUrl = `${environment.API_URL}/secure/payment/get-patient-info/${patientId}?ciphertext=${ciphertext}`;

        return this.http
            .get<any>(`${apiUrl}`, { headers })
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError((error) => this.handleUnauthorized(error))
            );
    }

    getPatientBalance(patientId: string): Observable<any> {
        const ciphertext = this.getCiphertext();

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const apiUrl = `${environment.API_URL}/secure/payment/balance/${patientId}?ciphertext=${ciphertext}`;

        return this.http
            .get<any>(`${apiUrl}`, { headers })
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError((error) => this.handleUnauthorized(error))
            );
    }

    getCiphertextWithFetch(patientInfo: any): Observable<any> {
        const ciphertext = this.getCiphertext();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const url = `${environment.API_URL}/secure/director/encrypt?ciphertext=${ciphertext}`;

        const data = {
            appointmentid: patientInfo.data.appointmentid || '',
            copayamount: patientInfo.data.copayamount || '',
            departmentid: patientInfo.data.departmentid || '12',
            otheramount: patientInfo.data.otheramount,
            patientid: patientInfo.data.patientid,
            practiceid: patientInfo.data.practiceid || '16521',
        };

        return this.http
            .post<any>(url, data, { headers })
            .pipe(
                map((response: any) => {
                    if (!response) {
                        throw new Error(response?.message || 'Error fetching ciphertext');
                    }
                    return response;
                }),
                catchError((error) => {
                    console.error('Error in POST request for ciphertext:', error);
                    return this.handleUnauthorized(error);
                })
            );
    }
}
