<form [formGroup]="myForm">
  <h2 class="h2-section-title">
    Over the last two weeks how often have you been bothered by the following
    problems?
  </h2>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['littleinterest']?.is_include"
    controlName="littleinterest"
    [label]="radioItems['littleinterest']?.title"
    [options]="radioItems['littleinterest']?.options"
    optionsTitle="{{ radioItems['littleinterest']['options_title'] }}"
    titleNumber="{{ radioItems['littleinterest']['titleNumber'] }}"
    subTitle="{{ radioItems['littleinterest']['subTitle'] }}"
    [iconClass]="radioItems['littleinterest']?.icon"
    [separateTitle]="radioItems['littleinterest']?.separateTitle"
    [hint]="radioItems['littleinterest']?.hint"
    [questionId]="radioItems['littleinterest']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingdown']?.is_include"
    controlName="feelingdown"
    [label]="radioItems['feelingdown']?.title"
    [options]="radioItems['feelingdown']?.options"
    [iconClass]="radioItems['feelingdown']?.icon"
    [separateTitle]="radioItems['feelingdown']?.separateTitle"
    optionsTitle="{{ radioItems['feelingdown']['options_title'] }}"
    titleNumber="{{ radioItems['feelingdown']['titleNumber'] }}"
    subTitle="{{ radioItems['feelingdown']['subTitle'] }}"
    [hint]="radioItems['feelingdown']?.hint"
    [questionId]="radioItems['feelingdown']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['troublefalling']?.is_include"
    controlName="troublefalling"
    [label]="radioItems['troublefalling']?.title"
    [options]="radioItems['troublefalling']?.options"
    [iconClass]="radioItems['troublefalling']?.icon"
    [separateTitle]="radioItems['troublefalling']?.separateTitle"
    optionsTitle="{{ radioItems['troublefalling']['options_title'] }}"
    titleNumber="{{ radioItems['troublefalling']['titleNumber'] }}"
    [subTitle]="radioItems['troublefalling']?.subTitle"
    [hint]="radioItems['troublefalling']?.hint"
    [questionId]="radioItems['troublefalling']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingtired']?.is_include"
    controlName="feelingtired"
    [label]="radioItems['feelingtired']?.title"
    [options]="radioItems['feelingtired']?.options"
    [iconClass]="radioItems['feelingtired']?.icon"
    [separateTitle]="radioItems['feelingtired']?.separateTitle"
    optionsTitle="{{ radioItems['feelingtired']['options_title'] }}"
    titleNumber="{{ radioItems['feelingtired']['titleNumber'] }}"
    [subTitle]="radioItems['feelingtired']?.subTitle"
    [hint]="radioItems['feelingtired']?.hint"
    [questionId]="radioItems['feelingtired']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['poorappetite']?.is_include"
    controlName="poorappetite"
    [label]="radioItems['poorappetite']?.title"
    [options]="radioItems['poorappetite']?.options"
    [iconClass]="radioItems['poorappetite']?.icon"
    [separateTitle]="radioItems['poorappetite']?.separateTitle"
    optionsTitle="{{ radioItems['poorappetite']['options_title'] }}"
    titleNumber="{{ radioItems['poorappetite']['titleNumber'] }}"
    [subTitle]="radioItems['poorappetite']?.subTitle"
    [hint]="radioItems['poorappetite']?.hint"
    [questionId]="radioItems['poorappetite']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingbad']?.is_include"
    controlName="feelingbad"
    [label]="radioItems['feelingbad']?.title"
    [options]="radioItems['feelingbad']?.options"
    [iconClass]="radioItems['feelingbad']?.icon"
    [separateTitle]="radioItems['feelingbad']?.separateTitle"
    optionsTitle="{{ radioItems['feelingbad']['options_title'] }}"
    titleNumber="{{ radioItems['feelingbad']['titleNumber'] }}"
    [subTitle]="radioItems['feelingbad']?.subTitle"
    [hint]="radioItems['feelingbad']?.hint"
    [questionId]="radioItems['feelingbad']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['trouble']?.is_include"
    controlName="trouble"
    [label]="radioItems['trouble']?.title"
    [options]="radioItems['trouble']?.options"
    [iconClass]="radioItems['trouble']?.icon"
    [separateTitle]="radioItems['trouble']?.separateTitle"
    optionsTitle="{{ radioItems['trouble']['options_title'] }}"
    titleNumber="{{ radioItems['trouble']['titleNumber'] }}"
    [subTitle]="radioItems['trouble']?.subTitle"
    [hint]="radioItems['trouble']?.hint"
    [questionId]="radioItems['trouble']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
</form>
