<div class="h-body-selector selector">
  <div class="slider-select-title">Select how the pain feels</div>
  <div *ngFor="let item of items" class="bottom-checkbox">
    <mat-checkbox
      [checked]="selectedItems.includes(item.value)"
      (change)="toggleSelection(item, $event.checked)"
    >
      {{ item?.title | translate }}
    </mat-checkbox>
  </div>
  <button
    mat-raised-button
    class="next-btn btn-slider nextBtn"
    (click)="confirmSelection()"
    [disabled]="selectedItems.length === 0"
  >
    Next
  </button>
</div>
