import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButton } from "@angular/material/button";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-bottom-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    MatButton,
    TranslateModule
  ],
  templateUrl: './bottom-selector.component.html',
  styleUrls: ['./bottom-selector.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BottomSelectorComponent {
  selectedItems: string[] = []; // Array to store selected values
  items: any[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomSelectorComponent>
  ) {
    if (data) {
      this.items = data.items || [];
      this.selectedItems = data.selectedItem?.value || [];
    }
  }

  toggleSelection(item: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedItems.push(item.value);
    } else {
      this.selectedItems = this.selectedItems.filter(i => i !== item.value);
    }
  }

  confirmSelection() {
    this.bottomSheetRef.dismiss(this.selectedItems); // Return the selected items array
  }
}

